import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { Column } from '../components/Grid';
import Layout from '../components/Layout';
import { SectionRow } from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import { AllSectionProps } from '../components/types';

export const notFoundQuery = graphql`
  query NotFoundQuery {
    ...AllSectionFragment
  }
`;

const NotFoundPage = ({ data }: { data: AllSectionProps }) => {
  const homeSection = data.allContentfulSection.edges[0].node;
  const notFoundSection = { slug: '/404', title: 'Page not found' };

  return (
    <Layout
      title="Page not found"
      currentSectionPath={notFoundSection.slug}
      currentLocation={notFoundSection.slug}
      menuData={{ allContentfulSection: data.allContentfulSection }}
      breadcrumbPath={[
        {
          slug: homeSection.slug,
          title: homeSection.title
        },
        notFoundSection
      ]}
    >
      <SectionTitle headline="We can't find the page you're looking for!" />
      <SectionRow>
        <Column md={12}>
          <ul>
            <li>
              Visit the RBV Energy <Link to="/">home page</Link>
            </li>
            <li>
              Check out our{' '}
              <Link to="/products-services">products and services</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact us today</Link>
            </li>
          </ul>
        </Column>
      </SectionRow>
    </Layout>
  );
};

export default NotFoundPage;
